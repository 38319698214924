import { Typography, message, Input, Row, Col } from "antd";
import { useState, useEffect } from "react";
import PersonasIndex from "./PersonasIndex"; // Adjust the import path as needed
import axios from "axios";
import { useAuth } from "../../layout/auth";

export default function Persons() {
  const [personas, setPersonas] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [loading, setLoading] = useState(false);

  const auth = useAuth(); // Assuming you still want to use auth for fetching data

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/personas`) // Adjust the endpoint as needed
      .then((res) => {
        setPersonas(res.data);
        setFilteredPersonas(res.data); // Initialize filtered personas
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (search) {
      const filtered = personas.filter((persona) => {
        return (
          persona?.NombreCompleto?.toLowerCase().includes(search.toLowerCase()) ||
          persona?.TipoPersona?.toLowerCase().includes(search.toLowerCase()) ||
          persona?.Nacionalidad?.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredPersonas(filtered);
    } else {
      setFilteredPersonas(personas);
    }
  }, [search, personas]);

  return (
    <div>
      <Typography.Title level={3} type="secondary">
        Personas en la base de datos
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input.Search 
            placeholder="Buscar personas" 
            onSearch={(value) => setSearch(value)} 
            enterButton="Buscar" 
            loading={loading} 
          />
        </Col>
      </Row>
      <PersonasIndex personas={filteredPersonas} loading={loading} />
    </div>
  );
}
