import { Typography, message, Input, Button, Row, Col, Space, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import VerificationsIndex from "./VerificationsIndex";
import AddVerificationForm from "./forms/general/AddVerificationForm";
import axios from "axios";
import { useAuth } from "../../layout/auth";

export default function Verifications() {
  const [selectedVerification, setSelectedVerification] = useState(null);
  const [verifications, setVerifications] = useState();
  const [search, setSearch] = useState();
  const [filteredVerifications, setFilteredVerifications] = useState();
  const [loading, setLoading] = useState(false);
  const [agregarVerificacionVisible, setAgregarVerificacionVisible] = useState(false);
  const [clonarVerificacionVisible, setClonarVerificacionVisible] = useState(false);
  const [eliminarVerificacionVisible, setEliminarVerificacionVisible] = useState(false);

  useEffect(() => {
    console.log("UvieID", auth);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/verificaciones`, {
        params: { u: auth.UvieID },
      })
      .then((res) => {
        setVerifications(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (search) {
      const filtered = verifications.filter((verification) => {
        return (
          verification?.ControlInterno?.toString().toLowerCase().includes(search.toLowerCase()) ||
          verification?.DescripcionBreve?.toString().toLowerCase().includes(search.toLowerCase()) ||
          verification?.NombreCliente?.toString().toLowerCase().includes(search.toLowerCase()) ||
          verification?.NombreCompleto?.toString().toLowerCase().includes(search.toLowerCase()) ||
          verification?.ClasificacionSCIAN?.toString().toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredVerifications(filtered);
    } else {
      setFilteredVerifications(verifications);
    }
  }, [search, verifications]);

  const auth = useAuth();

  return (
    <div>
      <Typography.Title level={3} type="secondary">
        Verificaciones
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input.Search
            placeholder="Buscar"
            onSearch={(value) => {
              setSearch(value);
            }}
            enterButton="Buscar"
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAgregarVerificacionVisible(true);
              }}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<CopyOutlined />}
              onClick={() => {
                if (selectedVerification) {
                  setClonarVerificacionVisible(true);
                } else {
                  message.error("Seleccione una verificación para clonar");
                }
              }}
            >
              Clonar
            </Button>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (auth.rol !== "director") {
                  message.error("No tiene permisos para eliminar verificaciones");
                } else if (selectedVerification) {
                  setEliminarVerificacionVisible(true);
                } else {
                  message.error("Seleccione una verificación para eliminar");
                }
              }}
            >
              Eliminar
            </Button>
          </Space>
        </Col>
      </Row>
      <VerificationsIndex
        verifications={search ? filteredVerifications : verifications}
        loading={loading}
        setSelectedVerification={setSelectedVerification}
      />
      <AddVerificationForm
        visible={agregarVerificacionVisible}
        setVisible={setAgregarVerificacionVisible}
        setVerifications={setVerifications}
        loading={loading}
        setLoading={setLoading}
      />
      <Modal
        title="Clonar verificación"
        visible={clonarVerificacionVisible}
        onOk={() => {
          setLoading(true);
          setClonarVerificacionVisible(false);
          axios
            .post(`${process.env.REACT_APP_API_URL}/verificaciones/clone/1/${selectedVerification}`)
            .then((res) => {
              setLoading(false);
              message.success("Verificación clonada exitosamente");
              axios
                .get(`${process.env.REACT_APP_API_URL}/verificaciones`)
                .then((res) => {
                  setVerifications(res.data);
                  message.success("Lista de verificaciones actualizada");
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                  message.error("Error al actualizar lista de verificaciones");
                });
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              message.error("Error al clonar la verificación");
            });
        }}
        onCancel={() => {
          setClonarVerificacionVisible(false);
        }}
        okButtonProps={{ loading: loading }}
      >
        <Typography.Title level={4}>¿Está seguro de que desea clonar la verificación?</Typography.Title>
        <Typography.Text>
          Esta acción crea una copia de la verificación seleccionada, incluyendo sus visitas. Puede borrarse más adelante si así lo desea.
        </Typography.Text>
      </Modal>
      <Modal
        title="Eliminar verificación"
        visible={eliminarVerificacionVisible}
        onOk={() => {
          setLoading(true);
          setEliminarVerificacionVisible(false);
          axios
            .delete(`${process.env.REACT_APP_API_URL}/verificaciones/${selectedVerification}`)
            .then((res) => {
              setLoading(false);
              message.success("Verificación eliminada");
              axios
                .get(`${process.env.REACT_APP_API_URL}/verificaciones`)
                .then((res) => {
                  setVerifications(res.data);
                  message.success("Lista de verificaciones actualizada");
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                  message.error("Error al actualizar lista de verificaciones");
                });
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              message.error("Error al eliminar verificación");
            });
        }}
        onCancel={() => {
          setEliminarVerificacionVisible(false);
        }}
      >
        <Typography.Title
          level={4}
          style={{
            textAlign: "center",
            color: "red",
          }}
        >
          ¿Está seguro de que desea eliminar la verificación?
        </Typography.Title>
        <Typography.Text>Esta acción no se puede deshacer. Si continúa, se eliminará la verificación seleccionada.</Typography.Text>
      </Modal>
    </div>
  );
}
