import { Card, Button, message } from "antd";
import { CheckCircleFilled, FileWordFilled } from "@ant-design/icons";
import axios from "axios";

export default function TemplatePreview(props) {
  const { templateFields, templateName, id, loading, title } = props;

  const Filled = Object.fromEntries(Object.entries(templateFields).filter(([key, value]) => value != null && value !== ""));
  const nullKeys = Object.keys(templateFields).filter(
    (key) => templateFields[key] === null || templateFields[key] === "" || templateFields[key] === undefined
  );
  const CountOfFilled = Object.keys(Filled).length;
  const CountOfAll = Object.keys(templateFields).length;

  return (
    <div>
      <Card
        hoverable
        actions={[
          <>
            <Button
              key="download"
              icon={<FileWordFilled style={{ fontSize: "20px", color: CountOfFilled === CountOfAll ? "#2b579a" : "#ccc" }} />}
              onClick={() => {
                axios({
                  method: "get",
                  url: `${process.env.REACT_APP_API_URL}/formatos/${templateName}/${id}`,
                  responseType: "blob",
                })
                  .then((response) => {
                    console.log(response.headers["content-type"]);
                    if (response.headers["content-type"] === "application/zip") {
                      const fileName = `${templateName}_${new Date().getTime()}.zip`;
                      const blob = new Blob([response.data], { type: "application/zip" });
                      const link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = fileName;
                      link.click();
                    } else if (
                      response.headers["content-type"] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                      const fileName = `${templateName}_${new Date().getTime()}.docx`;
                      const blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      });
                      const link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = fileName;
                      link.click();
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error("Error al generar el documento");
                  });
              }}
              disabled={CountOfFilled !== CountOfAll}
              loading={loading}
              type="text"
              style={{ width: "100%" }}
            >
              Descargar
            </Button>
          </>,
        ]}
        title={title}
        size="small"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "75px",
            width: "100%",
            padding: "1rem",
          }}
        >
          {CountOfFilled === CountOfAll ? (
            <CheckCircleFilled style={{ color: "#52c41a", fontSize: "24px" }} />
          ) : (
            <Button
              type="link"
              onClick={() => {
                message.warn({
                  content: (
                    <div style={{ textAlign: "left" }}>
                      <strong>Campos faltantes en {title}:</strong>
                      <br />
                      {nullKeys.length > 0 ? (
                        nullKeys.map((key, index) => (
                          <span key={key}>
                            {index + 1}. {key}x
                            <br />
                          </span>
                        ))
                      ) : (
                        <span>No hay campos faltantes.</span>
                      )}
                    </div>
                  ),
                  duration: 3,
                });
              }}
              style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {CountOfFilled}/{CountOfAll}
              </span>
              <span style={{ fontSize: "12px", color: "#aaa" }}>{`Faltan ${nullKeys.length} campos`}</span>
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}
