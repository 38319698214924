// CREATE TABLE cotizaciones (
//     CotizacionID INT AUTO_INCREMENT PRIMARY KEY,
//     Solicitante VARCHAR(100) DEFAULT NULL,
//     FechaCotizacion DATE DEFAULT NULL,
//     Calle VARCHAR(100) DEFAULT NULL,
//     NumeroInterior VARCHAR(100) DEFAULT NULL,
//     NumeroExterior VARCHAR(100) DEFAULT NULL,
//     EntreCalle1 VARCHAR(100) DEFAULT NULL,
//     EntreCalle2 VARCHAR(100) DEFAULT NULL,
//     Colonia VARCHAR(100) DEFAULT NULL,
//     CodigoPostal VARCHAR(10) DEFAULT NULL,
//     Municipio VARCHAR(100) DEFAULT NULL,
//     Estado VARCHAR(100) DEFAULT NULL,
//     TipoTension VARCHAR(100) DEFAULT NULL,
//     CargaInstalada DOUBLE DEFAULT NULL,
//     TipoServicio VARCHAR(255) DEFAULT NULL,
//     PrecioReferencia DOUBLE DEFAULT NULL,
//     PrecioFinal DOUBLE DEFAULT NULL
//     );

import { Form, Input, Button, InputNumber, Space, Divider, Modal, DatePicker, AutoComplete, Row, Col } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";

export default function CotizacionesForm({ visible, onCancel, setCotizacionesList, record, isNew }) {
  const [form] = Form.useForm();

  const tensionOptions = [
    { value: "Baja" },
    { value: "Media" },
    { value: "Alta" },
    { value: "Baja y Media" },
    { value: "Media y Alta" },
    { value: "Baja, Media y Alta" },
  ];

  const servicioOptions = [{ value: "Residencial" }, { value: "Comercial" }, { value: "Industrial" }];

  const handleCloseCotizacionesForm = () => {
    form.resetFields();
    onCancel();
  };

  const handleCotizacionSubmit = () => {
    if (isNew) {
      handleCotizacionCreate();
    } else {
      handleCotizacionUpdate();
    }
  };

  const handleCotizacionUpdate = () => {
    form.validateFields().then((values) => {
      axios
        .put(`${process.env.REACT_APP_API_URL}/cotizaciones/${record.CotizacionID}`, {
          Solicitante: form.getFieldValue("Solicitante"),
          FechaCotizacion: moment(form.getFieldValue("FechaCotizacion")).format("YYYY-MM-DD"),
          Calle: form.getFieldValue("Calle"),
          NumeroExterior: form.getFieldValue("NumeroExterior"),
          NumeroInterior: form.getFieldValue("NumeroInterior"),
          EntreCalle1: form.getFieldValue("EntreCalle1"),
          EntreCalle2: form.getFieldValue("EntreCalle2"),
          Colonia: form.getFieldValue("Colonia"),
          CodigoPostal: form.getFieldValue("CodigoPostal"),
          Municipio: form.getFieldValue("Municipio"),
          Estado: form.getFieldValue("Estado"),
          TipoTension: form.getFieldValue("TipoTension"),
          CargaInstalada: form.getFieldValue("CargaInstalada"),
          TipoServicio: form.getFieldValue("TipoServicio"),
          PrecioReferencia: form.getFieldValue("PrecioReferencia"),
          PrecioFinal: form.getFieldValue("PrecioFinal"),
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/cotizaciones`).then((res) => {
            setCotizacionesList(res.data);
          });
          handleCloseCotizacionesForm();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleCotizacionCreate = () => {
    console.log("create");
    form.validateFields().then((values) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/cotizaciones`, {
          Solicitante: form.getFieldValue("Solicitante"),
          FechaCotizacion: moment(form.getFieldValue("FechaCotizacion")).format("YYYY-MM-DD"),
          Calle: form.getFieldValue("Calle"),
          NumeroExterior: form.getFieldValue("NumeroExterior"),
          NumeroInterior: form.getFieldValue("NumeroInterior"),
          EntreCalle1: form.getFieldValue("EntreCalle1"),
          EntreCalle2: form.getFieldValue("EntreCalle2"),
          Colonia: form.getFieldValue("Colonia"),
          CodigoPostal: form.getFieldValue("CodigoPostal"),
          Municipio: form.getFieldValue("Municipio"),
          Estado: form.getFieldValue("Estado"),
          TipoTension: form.getFieldValue("TipoTension"),
          CargaInstalada: form.getFieldValue("CargaInstalada"),
          TipoServicio: form.getFieldValue("TipoServicio"),
          PrecioReferencia: form.getFieldValue("PrecioReferencia"),
          PrecioFinal: form.getFieldValue("PrecioFinal"),
        })
        .then((res) => {
          axios.get(`${process.env.REACT_APP_API_URL}/cotizaciones`).then((res) => {
            console.log(res.data);
            setCotizacionesList(res.data);
          });
          handleCloseCotizacionesForm();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        // Set the fields with the record data if it's an edit
        Solicitante: record.Solicitante,
        FechaCotizacion: moment(record.FechaCotizacion),
        Calle: record.Calle,
        NumeroExterior: record.NumeroExterior,
        NumeroInterior: record.NumeroInterior,
        EntreCalle1: record.EntreCalle1,
        EntreCalle2: record.EntreCalle2,
        Colonia: record.Colonia,
        CodigoPostal: record.CodigoPostal,
        Municipio: record.Municipio,
        Estado: record.Estado,
        TipoTension: record.TipoTension,
        CargaInstalada: record.CargaInstalada,
        TipoServicio: record.TipoServicio,
        PrecioReferencia: record.PrecioReferencia,
        PrecioFinal: record.PrecioFinal,
      });
    } else {
      form.resetFields(); // Reset fields if adding a new record
    }
  }, [record, form]);

  const requiredFieldProps = {
    rules: [{ required: true, message: "Campo obligatorio" }],
  };

  return (
    <>
      <Modal title="Datos de la cotización" visible={visible} onCancel={onCancel} footer={null} width={800}>
        <Form form={form} layout="vertical" name="cotizacionesForm">
          <Row gutter={(16, 40)}>
            <Col span={24}>
              <Form.Item label="Fecha de cotización" name="FechaCotizacion" {...requiredFieldProps}>
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ backgroundColor: "#f0f2f5", padding: "10px", borderRadius: "5px" }}>
                <Form.Item label="Solicitante" name="Solicitante" {...requiredFieldProps}>
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Tipo de tensión" name="TipoTension" {...requiredFieldProps}>
                      <AutoComplete options={tensionOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Carga instalada" name="CargaInstalada" {...requiredFieldProps}>
                      <InputNumber addonAfter="kW" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Tipo de servicio" name="TipoServicio" {...requiredFieldProps}>
                  <AutoComplete options={servicioOptions} />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Precio de referencia" name="PrecioReferencia" {...requiredFieldProps}>
                      <InputNumber addonBefore="$" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Precio final" name="PrecioFinal" {...requiredFieldProps}>
                      <InputNumber addonBefore="$" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ backgroundColor: "#f0f2f5", padding: "10px", borderRadius: "5px" }}>
                <Form.Item label="Calle" name="Calle" {...requiredFieldProps}>
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Numero exterior" name="NumeroExterior" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Numero interior" name="NumeroInterior">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Entre calle 1" name="EntreCalle1" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Entre calle 2" name="EntreCalle2" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Colonia" name="Colonia" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Código postal" name="CodigoPostal" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Municipio" name="Municipio" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Estado" name="Estado" {...requiredFieldProps}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 16,
            }}
          >
            <Space direction="horizontal">
              <Button type="primary" onClick={handleCotizacionSubmit}>
                Guardar
              </Button>
              <Button type="primary" onClick={onCancel}>
                Cancelar
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
}
