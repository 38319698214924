import { Form, Modal, Input, DatePicker, TimePicker, message, Button, Row, Col, Select } from "antd";
import { useEffect, useState } from "react";
import AnimateItems from "../../../../utils/AnimateItems";
import axios from "axios";
import { listasInspeccionFijas, listasInspeccionVariables } from "../formOptions";
import moment from "moment";
import "moment/locale/es";
moment.locale("es-mx");

export default function DesarrolloForm(props) {
  const { title, mainData: visita, setMainData, visible, setVisible } = props;
  const [form] = Form.useForm();
  const [selectedOption, setSelectedOption] = useState("Fija")

  const handleSelectLista = (value) => {
    form.setFieldsValue({
      ListaInspeccion: value === "Fija" ? null : "GENERALES",
    });
    setSelectedOption(value);
  };

  useEffect(() => {
    setSelectedOption(visita?.ListaTipo)
    form.setFieldsValue({
      ...visita,
      FechaVisita: moment.utc(visita?.FechaVisita).isValid() ? moment.utc(visita?.FechaVisita, "YYYY-MM-DD") : null,
      HoraInicio: moment.utc(visita?.HoraInicio, "HH:mm:ss").isValid() ? moment.utc(visita?.HoraInicio, "HH:mm:ss") : null,
      HoraTermino: moment.utc(visita?.HoraTermino, "HH:mm:ss").isValid() ? moment.utc(visita?.HoraTermino, "HH:mm:ss") : null,
      ListaInspeccion: visita?.ListaInspeccion ? visita?.ListaInspeccion.split(",") : null,
    });
  }, [visita, form]);

  return (
    <Modal title={title} visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} centered>
      <Form form={form} layout="vertical">
        <AnimateItems delay={0.15}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item name="FechaVisita" label="Fecha de visita" rules={[{ required: true, message: "Campo requerido" }]}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  disabledDate={(current) => current && current > moment().endOf("day")}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="HoraInicio" label="H. Inicio" rules={[{ required: true, message: "Campo requerido" }]}>
                <TimePicker
                  format={"HH:mm"}
                  style={{ width: "100%" }}
                  disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23] })}
                  minuteStep={5}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="HoraTermino" label="H. Fin" rules={[{ required: true, message: "Campo requerido" }]}>
                <TimePicker
                  format={"HH:mm"}
                  style={{ width: "100%" }}
                  disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 21, 22, 23] })}
                  minuteStep={5}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="ListaTipo" label="Lista tipo">
            <Select style={{ width: "100%" }} placeholder="Selecciona una lista tipo" onChange={handleSelectLista}>
              <Select.Option value="Fija">Fija</Select.Option>
              <Select.Option value="Variable" disabled>Variable</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="ListaInspeccion" label="Lista de inspección">
            {selectedOption === "Fija" ? (
              <Select style={{ width: "100%" }} placeholder="Selecciona una lista de inspección">
                {listasInspeccionFijas.map((lista) => (
                  <Select.Option key={lista.value} value={lista.label}>
                    {lista.label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select mode="multiple" style={{ width: "100%" }} placeholder="Selecciona una lista de inspección">
                {listasInspeccionVariables.map((lista) => (
                  <Select.Option key={lista.value} value={lista.label}>
                    {lista.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item name="ObjetoVisita" label="Objeto de visita" rules={[{ required: true, message: "Campo requerido" }]}>
            <Input.TextArea rows={1} />
          </Form.Item>
          <Form.Item name="CircunstanciaVisita" label="Circunstancia de visita" rules={[{ required: true, message: "Campo requerido" }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="NoConformidadesEncargado"
            label="No conformidades del encargado"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Input.TextArea rows={1} />
          </Form.Item>
          <Form.Item
            name="ObservacionesEncargado"
            label="Observaciones del encargado"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Input.TextArea rows={1} />
          </Form.Item>
          <Form.Item name="Acciones" label="Acciones" rules={[{ required: true, message: "Campo requerido" }]}>
            <Input.TextArea rows={1} />
          </Form.Item>
        </AnimateItems>
      </Form>
      <Button
        type="primary"
        onClick={() => {
          form.validateFields().then((values) => {
            const visitData = {
              ...visita,
              ...form.getFieldsValue(),
              HoraInicio: form.getFieldsValue().HoraInicio.format("HH:mm:ss"),
              HoraTermino: form.getFieldsValue().HoraTermino.format("HH:mm:ss"),
              ListaInspeccion: form.getFieldsValue().ListaInspeccion.toString(),
            };
            axios
              .put(`${process.env.REACT_APP_API_URL}/visitas/${visita.VisitaID}`, visitData)
              .then(() => {
                message.success("Campos actualizados correctamente");
                setVisible(false);
                axios
                  .get(`${process.env.REACT_APP_API_URL}/visitas/${visita.VerificacionID}`)
                  .then((res) => {
                    message.success("Lista de visitas actualizada correctamente");
                    setMainData(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error("Error al actualizar la lista de visitas");
                  });
              })
              .catch((err) => {
                console.log(err);
                message.error("Error al actualizar los campos");
              });
          });
        }}
      >
        Guardar
      </Button>
    </Modal>
  );
}
