import { Row, Col, Card, Typography, DatePicker, Tag } from "antd";
import { useEffect, useState } from "react";
import StatCard from "./StatCard";
import InspectorStats from "./InspectorStats";
import StatusPieChart from "./StatusPieChart";
import moment from "moment";
import { motion } from "framer-motion";
import axios from "axios";

function DiagonalMotionDiv(props) {
  return (
    <motion.div animate={{ x: [-50, 0], y: [-250, 0] }} transition={{ delay: props.delay, duration: 0.35, ease: "easeInOut" }}>
      {props.children}
    </motion.div>
  );
}

export default function Stats() {
  const { RangePicker } = DatePicker;

  const [dateRange, setDateRange] = useState([moment().subtract(1, "month"), moment()]);
  const [totalRegistrado, setTotalRegistrado] = useState(0);
  const [totalDictaminado, setTotalDictaminado] = useState(0);
  const [uvieSummary, setUvieSummary] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const costResponse = await axios.get(`${process.env.REACT_APP_API_URL}/estadisticas/verificaciones/costos`, {
          params: {
            rango1: moment(dateRange[0]).format("YYYY-MM-DD"),
            rango2: moment(dateRange[1]).format("YYYY-MM-DD"),
          },
        });
        setTotalRegistrado(costResponse.data[0]?.MontoTotal ?? 0);

        const folioResponse = await axios.get(`${process.env.REACT_APP_API_URL}/estadisticas/verificaciones/folios`, {
          params: {
            rango1: moment(dateRange[0]).format("YYYY-MM-DD"),
            rango2: moment(dateRange[1]).format("YYYY-MM-DD"),
          },
        });
        setTotalDictaminado(folioResponse.data[0]?.MontoFoliadoTotal ?? 0);

        const uvieResponse = await axios.get(`${process.env.REACT_APP_API_URL}/estadisticas/uvies`);
        setUvieSummary(uvieResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dateRange]);

  const columns = [
    {
      title: "NumeroUvie",
      dataIndex: "NumeroUvie",
      key: "NumeroUvie",
    },
    {
      title: "Nombre Completo",
      dataIndex: "NombreCompleto",
      key: "NombreCompleto",
    },
    {
      title: "TotalVerificaciones",
      dataIndex: "TotalVerificaciones",
      key: "TotalVerificaciones",
      render: (verifications) => {
        return verifications > 0 ? verifications : <Tag color="red">No tiene</Tag>;
      },
    },
    {
      title: "TotalMonto",
      dataIndex: "TotalMonto",
      key: "TotalMonto",
      responsive: ["lg"],
      render: (income) => {
        return income > 0 ? income.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : <Tag color="red">No tiene</Tag>;
      },
    },
    {
      title: "PromedioMonto",
      dataIndex: "PromedioMonto",
      key: "PromedioMonto",
      responsive: ["lg"],
      render: (average) => {
        return average > 0 ? average.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : <Tag color="red">No tiene</Tag>;
      },
    },
  ];

  const pieData = {
    labels: ["Pendientes", "Dictaminadas sin expediente", "Dictaminadas completas"],
    datasets: [
      {
        data: [totalRegistrado, totalDictaminado, totalRegistrado - totalDictaminado],
        backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
        borderWidth: 2,
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: window.innerWidth > 768,
        position: "top",
        align: "center",
        labels: {
          boxWidth: 50,
          padding: 10,
        },
      },
    },
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Typography.Title level={3} className="fancy-titles" type="secondary">
            Estadísticas
          </Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <RangePicker
            defaultValue={dateRange}
            format={"DD/MM/YYYY"}
            bordered={false}
            onChange={(dates) => {
              setDateRange(dates);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[60, 8]}>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.1}>
            <Card className="card-stats">
              <StatCard title="Registrado" value={(totalRegistrado ?? 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.2}>
            <Card className="card-stats">
              <StatCard title="Dictaminado" value={(totalDictaminado ?? 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} prefix="$" />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <DiagonalMotionDiv delay={0.4}>
            <Card className="card-stats">
              <StatCard
                title="No dictaminado"
                value={((totalRegistrado ?? 0) - (totalDictaminado ?? 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                prefix="$"
              />
            </Card>
          </DiagonalMotionDiv>
        </Col>
      </Row>
      <Row>
        <Typography.Title level={3} className="fancy-titles" type="secondary" style={{ paddingTop: "2vw" }}>
          Información adicional
        </Typography.Title>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <DiagonalMotionDiv delay={0.1}>
            <Card bordered={false} className="card-stats" title="Desempeño de los verificadores">
              <InspectorStats columns={columns} data={uvieSummary} />
            </Card>
          </DiagonalMotionDiv>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <DiagonalMotionDiv delay={0.3}>
            <Card bordered={false} className="card-stats" title="Estado de las verificaciones">
              <div
                style={{
                  maxWidth: "60%",
                  margin: "0 auto",
                  padding: "0 20px",
                }}
              >
                <StatusPieChart data={pieData} options={pieOptions} />
              </div>
            </Card>
          </DiagonalMotionDiv>
        </Col>
      </Row>
    </div>
  );
}
