import { Table, Tag } from "antd";

export default function PersonasPVIndex(props) {
  const { personas, loading } = props;

  const columns = [
    {
      title: "Nombre Completo",
      dataIndex: "NombreCompleto",
      key: "NombreCompleto",
      render: (text, record) => {
        if (record.TipoPersona === "Moral") {
          return <Tag color="blue">{text}</Tag>;
        } else {
          return text || <span className="subtle-text">N/D</span>;
        }
      },
    },
    {
      title: "Tipo de Persona",
      dataIndex: "TipoPersona",
      key: "TipoPersona",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
    {
      title: "Nacionalidad",
      dataIndex: "Nacionalidad",
      key: "Nacionalidad",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={personas}
        loading={loading}
        rowKey="PersonaID"
        pagination={{
          pageSize: 25,
          showSizeChanger: true,
          showLessItems: true,
        }}
        scroll={{
          x: "max-content",
          y: "calc(100vh - 300px)",
        }}
      />
    </div>
  );
}
