import { Typography, message, Input, Row, Col } from "antd";
import { useState, useEffect } from "react";
import ClientesIndex from "./ClientesIndex"; // Assume you have a component to display clients
import axios from "axios";
import { useAuth } from "../../layout/auth";

export default function Clients() {
  const [clientes, setClientes] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes`)
      .then((res) => {
        setClientes(res.data);
        setFilteredClientes(res.data); // Initialize filtered clients
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (search) {
      const filtered = clientes.filter((cliente) => {
        return (
          cliente?.NombreCliente?.toLowerCase().includes(search.toLowerCase()) ||
          cliente?.Email?.toLowerCase().includes(search.toLowerCase()) ||
          cliente?.Telefono?.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredClientes(filtered);
    } else {
      setFilteredClientes(clientes);
    }
  }, [search, clientes]);

  return (
    <div>
      <Typography.Title level={3} type="secondary">
        Clientes
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Input.Search placeholder="Buscar clientes" onSearch={(value) => setSearch(value)} enterButton="Buscar" loading={loading} />
        </Col>
      </Row>
      <ClientesIndex clientes={filteredClientes} loading={loading} />
    </div>
  );
}
