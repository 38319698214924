import { Table } from "antd";

export default function ClientesIndex(props) {
  const { clientes, loading } = props;

  const columns = [
    {
      title: "Nombre",
      dataIndex: "NombreCliente",
      key: "NombreCliente",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
    {
      title: "Apodo/Alias",
      dataIndex: "ApodoAlias",
      key: "ApodoAlias",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
    {
      title: "Teléfono",
      dataIndex: "Telefono",
      key: "Telefono",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
    {
      title: "Correo Electrónico",
      dataIndex: "CorreoElectronico",
      key: "CorreoElectronico",
      render: (text) => text || <span className="subtle-text">N/D</span>,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={clientes}
        loading={loading}
        rowKey="ClienteID"
        pagination={{
          pageSize: 25,
          showSizeChanger: true,
          showLessItems: true,
        }}
        scroll={{
          x: "max-content",
          y: "calc(100vh - 300px)",
        }}
      />
    </div>
  );
}
