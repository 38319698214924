import SvgStats from "./icons/SvgStats";
import SvgInspection from "./icons/SvgInspection";
import SvgCalendar from "./icons/SvgCalendar";
import SvgInspector from "./icons/SvgInspector";
import SvgClient from "./icons/SvgClient";
import SvgClient2 from "./icons/SvgClient2";
import SvgPeople from "./icons/SvgPeople";
import { Link } from "react-router-dom";

const createItem = (key, label, icon, children) => {
  return { key, label, icon, children };
};

const createLinkItem = (key, label, icon, to) => {
  return createItem(
    key,
    <Link to={to}>
      <span className="side-menu-item">{label}</span>
    </Link>,
    <span className="side-menu-item-icon">{icon}</span>
  );
};

const items = [
  createLinkItem("1", "Estadísticas", <SvgStats />, "/"),
  createLinkItem("2", "Verificaciones", <SvgInspection />, "/verificaciones"),
  createLinkItem("3", "Calendario", <SvgCalendar />, "/calendario"),
  createLinkItem("4-1", "Cotizaciones", <SvgInspector />, "/cotizaciones"),
  createLinkItem("5", "Avances", <SvgInspector />, "/avances"),
  createItem(
    "4",
    <span className="side-menu-item">Listas</span>,
    <span className="side-menu-item-icon">
      <SvgCalendar />
    </span>,
    [
      createLinkItem("4-2", "Clientes", <SvgClient />, "/clientes"),
      createLinkItem("4-3", "Razones sociales", <SvgClient2 />, "/razonessociales"),
      createLinkItem("4-4", "Personas", <SvgPeople />, "/personas"),
      // createLinkItem("4-5", "Mods", <SvgPeople />, "/mods"),
    ]
  ),
];

export default items;
